@font-face {
  font-family: 'cityburnregular';
  src: url('assets/cityburn-webfont.woff2') format('woff2'), url('assets/cityburn-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Peace';
  src: url('assets/peace.ttf') format('truetype');
}

@font-face {
  font-family: 'bebas-neue';
  src: url('assets/BebasNeue-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.root-blanksy .bgio-client {
  width: 100%;
  height: 100%;
}

.root-blanksy {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.root-blanksy {
  margin: 0;
  font-family: 'cityburnregular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
}
.root-blanksy * {
  font-family: 'cityburnregular';
}

.root-blanksy .fill-height {
  height: 100%;
}

.root-blanksy .text--subtitle {
  font-size: 64pt;
  line-height: 81pt;
  font-weight: normal;
}

.root-blanksy .text--md {
  font-size: 48pt;
  line-height: 60pt;
  font-weight: normal;
}
.root-blanksy .text--sm {
  font-size: 22pt;
  line-height: 30pt;
  font-weight: normal;
}

.root-blanksy .text-uppercase {
  text-transform: uppercase;
}
.root-blanksy .font-weight-bold {
  font-weight: bold;
}

.root-blanksy .mt-5 {
  margin-top: 20px !important;
}

.root-blanksy .px-0 {
  padding-left: 2px;
  padding-right: 2px;
}

.root-blanksy .px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.root-blanksy .mx-0 {
  margin-right: 2px;
  margin-left: 2px;
}

.root-blanksy .mx-1 {
  margin-left: 2px;
  margin-right: 2px;
}
.root-blanksy .mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}
.root-blanksy .mx-3 {
  margin-left: 18px;
  margin-right: 18px;
}

.root-blanksy .font-family-monospaced,
.root-blanksy .font-family-monospaced * {
  font-family: Courier New, monospace !important;
}

.root-blanksy .code-digit {
  border-bottom: 6px dotted;
  min-width: 120px;
}

.root-blanksy .flex {
  flex: 1;
}
.root-blanksy .d-flex {
  display: flex !important;
}
.root-blanksy .flex-column {
  flex-direction: column !important;
}
.root-blanksy .flex-row {
  flex-direction: row !important;
}

.root-blanksy .d-inline-block {
  display: inline-block;
}

.root-blanksy .w-100 {
  width: 100%;
}
.root-blanksy .justify-center {
  justify-content: center !important;
}
.root-blanksy .justify-space-around {
  justify-content: space-around;
}
.root-blanksy .align-center {
  align-items: center;
}

.root-blanksy .text--white {
  color: white;
}
.root-blanksy .text--orange {
  color: orange;
}
.root-blanksy .text--grey {
  color: grey;
}
.root-blanksy .text--green {
  color: #00a700;
}
.root-blanksy .text--black {
  color: #000000;
}
.root-blanksy .text-sm {
  font-size: 0.8em;
}
.root-blanksy .text-xs {
  font-size: 0.5em;
  line-height: 0.8em;
}
.root-blanksy .text-xl {
  font-size: 2em;
}
.root-blanksy .muted {
  opacity: 0.7;
}
.root-blanksy .text-h3 {
  font-size: 1.6em;
  line-height: 0.9em;
}
.root-blanksy .text-h4 {
  font-size: 1.3em;
}
.root-blanksy .text-center {
  text-align: center;
}
.root-blanksy .text-left {
  text-align: left;
}
.root-blanksy .text-underline {
  text-decoration: underline;
}
.root-blanksy .overflow-auto {
  overflow: auto;
}

.root-blanksy .position-relative {
  position: relative;
}
.root-blanksy .position-absolute {
  position: absolute;
  z-index: 1;
}

.root-blanksy .avatar {
  overflow: hidden;
  max-width: 100px;
  padding: 0 15px;
}

.root-blanksy .avatar.avatar--xl {
  max-width: 220px;
}
.root-blanksy .avatar.avatar--xxl {
  max-width: 300px;
}

.root-blanksy .avatar img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 3px solid;
}

.root-blanksy .leader .avatar img {
  border-color: orange;
}

.root-blanksy .activePlayer {
  color: #0000ad;
}
.root-blanksy table {
  font-weight: 300;
  min-width: 1000px;
}

.root-blanksy .bg-shadow {
  background: rgba(0, 0, 0, 0.6);
}
.root-blanksy .bg-shadow--light {
  background: rgba(255, 255, 255, 0.6);
}

.root-blanksy .transition--fadeIn {
  opacity: 0;
  transition: opacity 0.3s;
}
.root-blanksy .transition--fadeIn.transition--active {
  opacity: 1;
}

@keyframes scroll {
  0% {
    margin-top: 300px;
  }
  100% {
    margin-top: -1300px;
  }
}
.root-blanksy .animation--scroll {
  overflow: hidden;
}
.root-blanksy .animation--scroll > div:first-child {
  animation: scroll 24s infinite;
}

@keyframes shrink-and-fade-in {
  0% {
    opacity: 0;
    transform: scale(3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.root-blanksy .shrink-and-fade-in {
  opacity: 0;
  animation: shrink-and-fade-in 0.5s forwards;
}
