.root-blanksy .yard-and-display_root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-blanksy .yard-and-display_title {
  margin-top: -80px;
  font-size: 164px;
  align-self: center;
  letter-spacing: 10px;
}

.root-blanksy .yard-and-display_subtitle {
  font-size: 64px;
  margin-top: -80px;
  animation: yard-and-display_subtitle-anim 10s 2s infinite;
}

@keyframes yard-and-display_subtitle-anim {
  5% {
    transform: rotate(2deg) scale(1.2);
  }
  10% {
    transform: rotate(-2deg) scale(1.2);
  }
  15% {
    transform: rotate(0) scale(1);
  }
}

.root-blanksy .yard-and-display_qr-container {
  background-image: url('./assets/entryFlow/qr-frame.webp');
  background-size: contain;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
  width: 525px;
  height: 700px;
}

.root-blanksy .yard-and-display_qr-child-container {
  width: calc(100% - 17px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.root-blanksy .yard-and-display_qr {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 80px;
  width: 75%;
  height: 50%;
}

.root-blanksy .yard-and-display_code-title {
  line-height: 1;
}

.root-blanksy .yard-and-display_code-link {
  font-family: Arial;
  margin-top: 10px;
  line-height: 1.2;
  text-decoration: underline;
  color: #3366cc;
}

.root-blanksy .yard-and-display_code {
  line-height: 1;
  color: #d46094;
  text-transform: uppercase;
}

.root-blanksy .yard-and-display_rat {
  right: 300px;
  transition: 1s 1.5s transform, 3s 2s right, 1s 3s opacity;
}

.root-blanksy .yard-and-display_rat-disappear {
  transform: scale(-1, 1);
  right: -100px;
  opacity: 0;
}

.root-blanksy .yard-and-display_emoji-loco {
  transition: 2s;
  background-image: url('./assets/entryFlow/emoji-loco.webp');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  height: 400px;
  width: 400px;
  top: 100px;
  right: 400px;
  mix-blend-mode: multiply;
}

.root-blanksy .yard-and-display_crown {
  position: absolute;
  top: 400px;
  right: 175px;
  transition: 2s;
  transition-delay: 2s;
  animation: yard-and-display_crown 5s infinite ease-in-out;
}

@keyframes yard-and-display_crown {
  50% {
    transform: translateY(10px);
  }
}

.root-blanksy .yard-and-display_crown-disappear {
  opacity: 0;
}

.root-blanksy .yard-and-display_yard-part {
  margin-top: 65px;
  position: absolute;
  width: 100%;
  height: 100%;
  animation: yard-and-display_yard-part-appear 1s 1.5s forwards;
  opacity: 0;
}

@keyframes yard-and-display_yard-part-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.root-blanksy .yard-and-display_white-spray {
  background-image: url('./assets/entryFlow/spray-white.webp');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  height: 300px;
  width: 200px;
  bottom: 120px;
  left: 625px;
}
