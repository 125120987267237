.root-blanksy .crown_crown {
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  width: 150px;
  mix-blend-mode: multiply;
}

.root-blanksy .crown_light {
  background-image: url('../assets/entryFlow/crown.webp');
}

.root-blanksy .crown_dark {
  background-image: url('../assets/winner/crown.webp');
}
