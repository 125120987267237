.root-blanksy .score-summary_root {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 85%;
  width: 80%;
  margin-left: 20%;
}

.root-blanksy .score-summary_title-container {
  position: absolute;
  top: 0;
  text-align: center;
  transition: left 1s;
  left: 50%;
}

.root-blanksy .score-summary_subtitle {
  font-size: 64px;
  line-height: 1;
  margin-top: 12px;
}

.root-blanksy .score-summary_title-container-with-emoji {
  left: 35%;
}

.root-blanksy .score-summary_col-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-blanksy .score-summary_historic {
  margin-top: -40px;
  width: 210px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
}

.root-blanksy .score-summary_mark {
  background-position: bottom;
  width: 210px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  animation: score-summary_mark-appear 1s;
  mix-blend-mode: multiply;
}

.root-blanksy .score-summary_mark-mirror {
  transform: scaleX(-1);
}

@keyframes score-summary_mark-appear {
  0% {
    opacity: 0;
  }
}

.root-blanksy .score-summary_player-avatar {
  background-color: white;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.root-blanksy .score-summary_emoji-art-container {
  position: absolute;
  right: 400px;
  top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  mix-blend-mode: multiply;
  animation: score-summary_emoji-appear 1s;
}

.root-blanksy .score-summary_emoji-art {
  width: 200px;
  height: 165px;
  background-image: url('../../assets/ArtEmoji.webp');
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: -30px;
}

.root-blanksy .score-summary_emoji-nerd-container {
  right: 240px;
  top: 10px;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  mix-blend-mode: multiply;
  animation: score-summary_emoji-appear 1s;
}

.root-blanksy .score-summary_emoji-nerd {
  margin-bottom: -12px;
  width: 160px;
  height: 160px;
  background-image: url('../../assets/NerdEmoji.webp');
  background-repeat: no-repeat;
  background-size: contain;
}
.root-blanksy .score-summary_emoji-laugh-container {
  position: absolute;
  right: 40px;
  top: -10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  mix-blend-mode: multiply;
  animation: score-summary_emoji-appear 1s;
}
.root-blanksy .score-summary_emoji-laugh {
  width: 210px;
  height: 210px;
  margin-bottom: -40px;
  background-image: url('../../assets/LaughEmoji.webp');
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes score-summary_emoji-appear {
  0% {
    opacity: 0;
    transform: scale(2) rotate(10deg);
  }
}
