.root-blanksy .stage-root_root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 20%;
  text-align: center;
}

.root-blanksy .stage-root_sentence {
  margin-top: 16px;
  min-height: 116px;
  font-size: 58px;
  width: 75%;
  line-height: 1;
}
