.root-blanksy .transition-container_root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-items: center;
}
.root-blanksy .transition-container_title-image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  height: 35%;
  margin-top: 24px;
  mix-blend-mode: multiply;
}

.root-blanksy .transition-container_attention {
  background-image: url('../../assets/transition/attention-sign.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  margin-top: 80px;
  z-index: 1;
}
.root-blanksy .transition-container_attention-text-container {
  background-image: url('../../assets/transition/whitebackground-attention.webp');
  background-size: cover;
  background-repeat: no-repeat;
  width: 900px;
  height: 350px;
  position: absolute;
  bottom: 135px;
  left: 460px;
}

.root-blanksy .transition-container_attention-text-container-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: Peace;
  width: 70%;
  height: 70%;
  margin-top: 40px;
  margin-right: 70px;
  margin-left: auto;
}
.root-blanksy .transition-container_attention-title {
  color: #cb0203;
  font-size: 54px;
  text-align: center;
}
.root-blanksy .transition-container_attention-body {
  font-size: 42px;
  line-height: 1;
  text-align: center;
  white-space: pre-line;
}
