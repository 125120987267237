.root-blanksy .selfie {
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;
}

.root-blanksy .selfie_name {
  text-align: center;
  white-space: pre;
  line-height: 1;
}

.root-blanksy .selfie_name-optional {
  opacity: 0.5;
}
.root-blanksy .selfie_name-dummy {
  margin-top: -60px;
}
.root-blanksy .selfie_name-dummy-spacing {
  letter-spacing: 0;
}

.root-blanksy .animate-selfie {
  animation: FaceFloat 10s infinite;
}

@keyframes FaceFloat {
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-2.5px);
  }
}
