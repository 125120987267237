.root-blanksy .how-to-win_root {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 1%;
  flex-direction: column;
  margin-left: 20%;
}

.root-blanksy .how-to-win_title {
  animation: how-to-win_title-appear 1s;
}

@keyframes how-to-win_title-appear {
  0% {
    opacity: 0;
    transform: scale(2) rotate(20deg);
  }
}

.root-blanksy .how-to-win_anchor {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.root-blanksy .how-to-win_artist-container {
  position: absolute;
  bottom: 22%;
  left: 44%;
}

.root-blanksy .how-to-win_emoji-artist {
  background-image: url('../../assets/explainer/emoji-artist.webp');
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: multiply;
  width: 450px;
  height: 450px;
}

.root-blanksy .how-to-win_title-artist {
  font-family: 'bebas-neue';
  position: absolute;
  width: 300px;
  text-transform: uppercase;
  font-size: 26px;
  right: 0;
}

.root-blanksy .how-to-win_title-artist * {
  font-family: 'bebas-neue';
}
.root-blanksy .how-to-win_part-artist-subtitle {
  white-space: pre-line;
  text-align: center;
  margin-top: -150px;
  margin-right: -150px;
  line-height: 1;
  text-transform: uppercase;
  color: #79519a;
}

.root-blanksy .how-to-win_funny-container {
  position: absolute;
  left: 32%;
  top: 20%;
}

.root-blanksy .how-to-win_emoji-funny {
  background-image: url('../../assets/explainer/emoji-funny.webp');
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: multiply;
  width: 450px;
  height: 450px;
}

.root-blanksy .how-to-win_title-funny {
  position: absolute;
  width: 270px;
  font-family: 'bebas-neue';
  text-transform: uppercase;
  transform: rotate(-25deg);
  font-size: 28px;
}

.root-blanksy .how-to-win_title-funny * {
  font-family: 'bebas-neue';
}
.root-blanksy .how-to-win_part-funny-subtitle {
  white-space: pre-line;
  text-align: center;
  margin-top: -150px;
  margin-right: -20px;
  line-height: 1;
  text-transform: uppercase;
  color: #21736f;
}

.root-blanksy .how-to-win_nerd-container {
  position: absolute;
  right: 12%;
  top: 15%;
}

.root-blanksy .how-to-win_emoji-nerd {
  background-image: url('../../assets/explainer/emoji-nerd.webp');
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: multiply;
  width: 450px;
  height: 450px;
}

.root-blanksy .how-to-win_title-nerd {
  position: absolute;
  width: 300px;
  transform: rotate(15deg);
  text-transform: uppercase;
  font-family: 'bebas-neue';
  font-size: 26px;
  top: 50px;
}

.root-blanksy .how-to-win_title-nerd * {
  font-family: 'bebas-neue';
}

.root-blanksy .how-to-win_part-nerd-subtitle {
  white-space: pre-line;
  text-align: center;
  margin-top: -150px;
  margin-right: -20px;
  line-height: 1;
  text-transform: uppercase;
  color: #01391b;
}

.root-blanksy .how-to-win_icon-appear-anim {
  opacity: 0;
  animation: how-to-win_icon-appear 1s forwards cubic-bezier(0.33, 0.27, 0.42, 1.44);
}

.root-blanksy .how-to-win_icon-title-appear-anim {
  opacity: 0;
  animation: how-to-win_icon-title-appear 1s forwards cubic-bezier(0.33, 0.27, 0.42, 1.44);
}

.root-blanksy .how-to-win_icon-subtitle-appear-anim {
  opacity: 0;
  animation: how-to-win_icon-subtitle-appear 1s 1s forwards cubic-bezier(0.33, 0.27, 0.42, 1.44);
}

@keyframes how-to-win_icon-title-appear {
  0% {
    width: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes how-to-win_icon-appear {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes how-to-win_icon-subtitle-appear {
  0% {
    opacity: 0;
    transform: scale(2) rotate(5deg);
  }
  100% {
    opacity: 1;
  }
}
