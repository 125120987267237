.root-blanksy .winner-transition_root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.root-blanksy .winner-transition_single-frame {
  background-image: url('../../assets/winner/single-winner-frame.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 600px;
  height: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  mix-blend-mode: multiply;
  align-items: center;
  margin-top: -40px;
}

.root-blanksy .winner-transition_crown {
  animation: crown-entry 2s forwards 1s;
  transform: translateY(-400px);
}

@keyframes crown-entry {
  100% {
    transform: translateY(100px);
  }
}

.root-blanksy .winner-transition_multiple-frame {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/winner/multiple-winner-frame.webp');
  width: 600px;
  height: 600px;
}

.root-blanksy .winner-transition_medal {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/winner/medal.webp');
  width: 167px;
  height: 200px;
  position: absolute;
  bottom: -15px;
  mix-blend-mode: multiply;
  background-position-y: -200px;
  animation: medal-enter 1s forwards 1.5s ease-out;
}

@keyframes medal-enter {
  100% {
    background-position-y: 0;
  }
}

.root-blanksy .winner-transition_confetti {
  background-image: url('../../assets/winner/confetti.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 1595px;
  height: 895px;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  animation: confetti-in 0.4s ease-in 1s forwards;
  transform: scale(0);
}

@keyframes confetti-in {
  100% {
    transform: scale(1);
  }
}

.root-blanksy .winner-transition_player {
  margin-bottom: 50px;
}
