.root-blanksy .queue-screen_root {
  width: 100%;
  height: 100%;
}

.root-blanksy .queue-screen_red-carpet {
  background-image: url('./assets/queue/red-carpet.png');
  width: 401px;
  height: 173px;
  position: absolute;
  bottom: 0;
  left: 287px;
  background-size: contain;
}

.root-blanksy .queue-screen_red-rope {
  background-image: url('./assets/queue/red-rope.png');
  width: 767px;
  height: 412px;
  background-size: contain;
  position: absolute;
  bottom: 20px;
  left: 100px;
}

.root-blanksy .queue-screen_red-rope-shade {
  background-image: url('./assets/queue/red-rope-shade.png');
  width: 1103px;
  height: 145px;
  mix-blend-mode: multiply;
  background-size: contain;
  position: absolute;
  bottom: 20px;
  left: 50px;
}

.root-blanksy .queue-screen_arrow {
  background-image: url('./assets/queue/arrow-curved.png');
  width: 313px;
  height: 272px;
  background-size: contain;
  position: absolute;
  top: 60px;
  transform: rotate(30deg);
  animation: queue-screen-arrow-anim 5s infinite;
}

@keyframes queue-screen-arrow-anim {
  12% {
    transform: rotate(20deg) scale(1.1);
  }
  18% {
    transform: rotate(30deg) scale(1);
  }
}

.root-blanksy .queue-screen_qr {
  top: 10%;
}

.root-blanksy .queue-screen_text-container {
  position: absolute;
  left: 14%;
  top: 40%;
  text-align: center;
}

.root-blanksy .queue-screen_queueing-title {
  font-size: 68px;
  letter-spacing: 5px;
}

.root-blanksy .queue-screen_queueing-subtitle {
  font-size: 50px;
  color: #9e1100;
  font-family: bebas-neue;
  width: 400px;
}

.root-blanksy .queue-screen_white-bg {
  background-image: url('./assets/queue/white-bg.png');
  width: 1002px;
  height: 799px;
  background-size: contain;
  position: absolute;
  top: 8%;
}

.root-blanksy .queue-screen_faces-container {
  position: absolute;
  left: 65%;
  top: 0;
  width: 100%;
  height: 100%;
}
