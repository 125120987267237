.root-blanksy .initial-loading_root {
  width: 100%;
  padding-left: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.root-blanksy .initial-loading_crown {
  transform: scale(1.7);
  margin-top: 100px;
}

.root-blanksy .initial-loading_title {
  font-size: 250px;
  margin-top: 0;
  letter-spacing: 22px;
}

.root-blanksy .initial-loading_subtitle {
  font-size: 80px;
  margin-top: 0;
  letter-spacing: 7px;
  margin-right: 23px;
}
