.root-blanksy .game-end_root {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  margin-left: 20%;
  align-items: center;
}

.root-blanksy .game-end_emoji {
  position: absolute;
  right: 0;
  bottom: 30%;
  background-image: url('../../assets/winner/emoji-stars.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 257px;
  height: 200px;
  mix-blend-mode: multiply;
}

.root-blanksy .game-end_lights-off {
  background-color: rgba(0, 0, 0, 0.53);
}

.root-blanksy .game-end_lights-on {
  background-image: url('../../assets/winner/light-on.webp');
  mix-blend-mode: multiply;
}

.root-blanksy .game-end_lights {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.root-blanksy .game-end_body {
  display: flex;
  margin-top: 10%;
  justify-content: flex-end;
  margin-right: 30%;
  width: 100%;
  animation: game-end_body-enter 1.5s ease-in;
}

@keyframes game-end_body-enter {
  0% {
    filter: blur(15px);
  }
  10% {
    transform: translate(-4px, 4px);
  }
  20% {
    transform: translate(-2px, 4px);
  }
  30% {
    transform: translate(0px, 0px);
    filter: blur(5px);
  }
  40% {
    transform: translate(-2px, 2px);
  }
  50% {
    transform: translate(-4px, 0px);
  }
  60% {
    filter: blur(10px);
    transform: translate(0px, 4px);
  }
  80% {
    transform: translate(2px, 2px);
  }
  100% {
    filter: blur(0);
  }
}

.root-blanksy .game-end_frame {
  background-image: url('../../assets/winner/AvatarFrame.webp');
  width: 450px;
  height: 585px;
  background-repeat: no-repeat;
  background-size: contain;
}

.root-blanksy .game-end_player-part {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  text-align: center;
}

.root-blanksy .game-end_right-container {
  display: flex;
  flex-direction: column;
}
