.root-blanksy .background_root {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 300%;
  display: flex;
  transition: transform cubic-bezier(0.66, -0.39, 0.32, 1.33);
}

.root-blanksy .background_root:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0;
  z-index: 0;
}

.root-blanksy .background_child-container {
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 1920px;
  height: 100%;
  display: flex;
}
