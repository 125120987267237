.root-blanksy .progress-bar-drain_root {
  position: absolute;
  bottom: 145px;
  right: 2vw;
}

.root-blanksy .progress-bar-drain_bomb-body {
  background-image: url('../../assets/progress-bar/bomb_core.png');
  width: 274px;
  height: 193px;
  background-size: contain;
  background-repeat: no-repeat;
}

.root-blanksy .progress-bar-drain_bomb-body-shade {
  background-image: url('../../assets/progress-bar/bomb_core_shade.png');
  top: 10%;
  left: 12%;
  width: 217px;
  height: 154px;
  background-size: contain;
  background-repeat: no-repeat;
  mix-blend-mode: overlay;
  position: absolute;
  opacity: 0.5;
}

.root-blanksy .progress-bar-drain_bomb-wire {
  background-image: url('../../assets/progress-bar/bomb_string.png');
  height: 120px;
  margin-left: 32px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -5px;
  animation: drain linear forwards;
}

.root-blanksy .progress-bar-drain_bomb-wire-shade {
  background-image: url('../../assets/progress-bar/bomb_string_shade.png');
  height: 120px;
  width: 100%;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  mix-blend-mode: overlay;
}

.root-blanksy .progress-bar-drain_spark-animation-container {
  animation: spark-shake linear 0.2s infinite;
  background-image: url('../../assets/progress-bar/bomb_spark.webp');
  width: 100px;
  height: 115px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: 50% 50%;
  position: relative;
}

.root-blanksy .progress-bar-drain_spark {
  translate: -35px;
  animation: spark linear forwards;
}

.root-blanksy .progress-bar-drain_spark-shade {
  background-image: url('../../assets/progress-bar/bomb_spark_shade.png');
  width: 100px;
  height: 115px;
  mix-blend-mode: overlay;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  opacity: 0.2;
}

@keyframes drain {
  0% {
    width: 65vw;
    left: -65vw;
  }
  100% {
    width: 0;
    left: 0;
  }
}

@keyframes spark {
  0% {
    transform: translateY(10px);
  }
  10% {
    transform: translateY(40px);
  }
  30% {
    transform: translateY(35px);
  }
  40% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(20px);
  }
  70% {
    transform: translateY(30px);
  }
  90% {
    transform: translateY(25px);
  }
  95% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(-80px);
  }
}

@keyframes spark-shake {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(-10deg);
  }
}

.root-blanksy .progress-bar-drain_aura {
  position: absolute;
  top: 20px;
  margin-left: -10px;
  background-color: red;
  opacity: 0.1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: aura-glow 1s infinite;
}

@keyframes aura-glow {
  0% {
    opacity: 0.05;
    transform: scale(0.9);
  }
  50% {
    opacity: 0.08;
    transform: scale(1);
  }
  100% {
    opacity: 0.05;
    transform: scale(0.9);
  }
}

.root-blanksy .progress-bar-drain_particle {
  position: absolute;
  bottom: 20px;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root-blanksy .progress-bar-drain_particle-body {
  display: block;
  position: absolute;
  bottom: 0;
  width: 0.1rem;
  height: 2rem;
  border-radius: 0.1rem;
  opacity: 0;
  background-color: white;
  transform-origin: bottom center;
  animation: sparkler-sparkle 1s infinite linear;
}

@keyframes sparkler-sparkle {
  0% {
    transform: translateY(-0.5rem) scaleY(0.25);
    opacity: 0;
  }
  10% {
    transform: translateY(-1rem) scaleY(0.5);
    opacity: 0.35;
  }
  30% {
    transform: translateY(-2rem) scaleY(0.5);
    opacity: 0.7;
  }
  50% {
    transform: translateY(-4rem) scaleY(1.5);
    opacity: 0.7;
  }
  51% {
    opacity: 0;
    transform: translateY(-4rem) scaleY(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0) scaleY(0.25);
  }
}
