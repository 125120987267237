.root-blanksy .rat_root {
  position: absolute;
  width: 500px;
  height: 500px;
}

.root-blanksy .rat_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.root-blanksy .rat_rat-image {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
}

.root-blanksy .rat_shade {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  mix-blend-mode: multiply;
  filter: blur(5px);
}

.root-blanksy .rat_mirror {
  transform: scaleX(-1);
}

.root-blanksy .rat_announcer {
  background-image: url('../assets/explainer/rat-announcer.webp');
}

.root-blanksy .rat_camera {
  background-image: url('../assets/winner/rat-camera.webp');
}

.root-blanksy .rat_announcer-shade {
  background-image: url('../assets/explainer/rat-announcer-shade.webp');
  height: 100%;
  width: 100%;
  top: 0;
  left: 8%;
}

.root-blanksy .rat_spray {
  background-image: url('../assets/entryFlow/rat-withspray.webp');
}

.root-blanksy .rat_spray-shade {
  background-image: url('../assets/entryFlow/rat-withspray-shade.webp');
  height: 80%;
  width: 80%;
  top: 20%;
  left: 20%;
}

.root-blanksy .rat_camera-shade {
  background-image: url('../assets/winner/rat-camera-shade.webp');
  height: 85%;
  width: 88%;
  left: 30%;
  top: 2%;
}
