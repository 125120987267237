.root-blanksy .how-to-play_root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 1%;
  flex-direction: column;
}

.root-blanksy .how-to-play_title {
  animation: name-appear 1s;
}

.root-blanksy .how-to-play_title2 {
  animation: name-appear 1s;
}

.root-blanksy .how-to-play_rat {
  bottom: 100px;
  left: -30%;
  animation: how-to-play_rat-enter 1s forwards;
}

.root-blanksy .how-to-play_arty {
  font-size: 64px;
  position: absolute;
  left: 15%;
  top: 33%;
  animation: name-appear 0.5s;
}

@keyframes how-to-play_rat-enter {
  100% {
    left: 2%;
  }
}

.root-blanksy .how-to-play_font-override * {
  font-family: 'bebas-neue';
}

.root-blanksy .how-to-play_draw-container {
  position: absolute;
  left: 50%;
  top: 55%;
}

.root-blanksy .how-to-play_anchor {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.root-blanksy .how-to-play_title-draw {
  position: absolute;
  width: 400px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 300px;
  margin-left: 170px;
  transform: rotate(-33deg);
}

.root-blanksy .how-to-play_draw-background {
  background-image: url('../../assets/how-to-play/draw-backstain.webp');
  width: 420px;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  mix-blend-mode: multiply;
}

.root-blanksy .how-to-play_draw-icon {
  background-image: url('../../assets/how-to-play/draw-icon.webp');
  width: 130px;
  height: 234px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}

.root-blanksy .how-to-play_fill-blanks-container {
  position: absolute;
  left: 33%;
  top: 50%;
}

.root-blanksy .how-to-play_title-fill-blanks {
  position: absolute;
  width: 350px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: -100px;
}

.root-blanksy .how-to-play_fill-blanks-background {
  background-image: url('../../assets/how-to-play/fillblanks-backstain.webp');
  width: 540px;
  height: 673px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  mix-blend-mode: multiply;
}

.root-blanksy .how-to-play_fill-blanks-icon {
  background-image: url('../../assets/how-to-play/fillinblanks-icon.webp');
  width: 340px;
  height: 170px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}

.root-blanksy .how-to-play_vote-container {
  position: absolute;
  left: 89%;
  top: 55%;
}

.root-blanksy .how-to-play_title-vote {
  position: absolute;
  width: 400px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 300px;
  margin-left: 80px;
  transform: rotate(-35deg);
}

.root-blanksy .how-to-play_vote-background {
  background-image: url('../../assets/how-to-play/vote-backstain.webp');
  width: 515px;
  height: 485px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  mix-blend-mode: multiply;
}

.root-blanksy .how-to-play_vote-icon {
  background-image: url('../../assets/how-to-play/vote-icon.webp');
  width: 375px;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}

.root-blanksy .how-to-play_guess-container {
  position: absolute;
  left: 70%;
  top: 50%;
}

.root-blanksy .how-to-play_title-guess {
  position: absolute;
  width: 350px;
  text-transform: uppercase;
  font-size: 20px;
  transform: rotate(25deg);
  margin-top: -160px;
}

.root-blanksy .how-to-play_guess-background {
  background-image: url('../../assets/how-to-play/guess-backstain.webp');
  width: 556px;
  height: 542px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  mix-blend-mode: multiply;
}

.root-blanksy .how-to-play_guess-icon {
  background-image: url('../../assets/how-to-play/guess-icon.webp');
  width: 375px;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 120px;
}

.root-blanksy .how-to-play_icon-appear-anim {
  opacity: 0;
  animation: icon-appear 1s cubic-bezier(0.33, 0.27, 0.42, 1.44) forwards;
}

.root-blanksy .how-to-play_backstain-appear-anim {
  opacity: 0;
  animation: backstain-appear 1s forwards;
}

.root-blanksy .how-to-play_title-appear-anim {
  opacity: 0;
  animation: title-appear 1s forwards cubic-bezier(0.33, 0.27, 0.42, 1.44);
}

@keyframes title-appear {
  0% {
    width: 0;
    transform: rotate(-100deg);
  }
  100% {
    opacity: 1;
  }
}

@keyframes backstain-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes icon-appear {
  0% {
    opacity: 0;
    transform: scale(1.5) rotate(15deg);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes name-appear {
  0% {
    opacity: 0;
    transform: scale(3) rotate(20deg);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
