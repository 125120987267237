.root-blanksy .qr-sign_root {
  position: absolute;
  left: 17%;
  top: 5%;
  z-index: 1;
}

.root-blanksy .qr-sign_background {
  background-image: url('./assets/qr-sign.webp');
  background-repeat: no-repeat;
  background-size: contain;
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.root-blanksy .qr-sign_body {
  margin-right: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  height: 70%;
}

.root-blanksy .qr-sign_qr {
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.root-blanksy .qr-sign_text {
  line-height: 1;
  font-family: 'bebas-neue';
  margin-top: -1%;
  font-size: 100%;
}
